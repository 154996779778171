import React from 'react'

export const WhyChooseUs = () => {
  return (
    <div>
        <>
        <div
  className="container-fluid py-5 wow fadeInUp"
  data-wow-delay="0.1s"
  style={{
    visibility: "visible",
    animationDelay: "0.1s",
    animationName: "fadeInUp"
  }}
>
  <div className="container py-5">
    <div
      className="section-title text-center position-relative pb-3 mb-5 mx-auto"
      style={{ maxWidth: 600 }}
    >
      <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
      <h1 className="mb-0">We Are Here to Grow Your Business Exponentially</h1>
    </div>
    <div className="row g-5">
      <div className="col-lg-4">
        <div className="row g-5">
          <div
            className="col-12 wow zoomIn"
            data-wow-delay="0.2s"
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "zoomIn"
            }}
          >
            <div
              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-cubes text-white" />
            </div>
            <h4>Best In Industry</h4>
            <p className="mb-0">
              Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam
              dolor
            </p>
          </div>
          <div
            className="col-12 wow zoomIn"
            data-wow-delay="0.6s"
            style={{
              visibility: "visible",
              animationDelay: "0.6s",
              animationName: "zoomIn"
            }}
          >
            <div
              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-award text-white" />
            </div>
            <h4>Award Winning</h4>
            <p className="mb-0">
              Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam
              dolor
            </p>
          </div>
        </div>
      </div>
      <div
        className="col-lg-4  wow zoomIn"
        data-wow-delay="0.9s"
        style={{
          minHeight: 350,
          visibility: "visible",
          animationDelay: "0.9s",
          animationName: "zoomIn"
        }}
      >
        <div className="position-relative h-100">
          <img
            className="position-absolute w-100 h-100 rounded wow zoomIn"
            data-wow-delay="0.1s"
            src="img/feature.jpg"
            style={{
              objectFit: "cover",
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "zoomIn"
            }}
          />
        </div>
      </div>
      <div className="col-lg-4">
        <div className="row g-5">
          <div
            className="col-12 wow zoomIn"
            data-wow-delay="0.4s"
            style={{
              visibility: "visible",
              animationDelay: "0.4s",
              animationName: "zoomIn"
            }}
          >
            <div
              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-users-cog text-white" />
            </div>
            <h4>Professional Staff</h4>
            <p className="mb-0">
              Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam
              dolor
            </p>
          </div>
          <div
            className="col-12 wow zoomIn"
            data-wow-delay="0.8s"
            style={{
              visibility: "visible",
              animationDelay: "0.8s",
              animationName: "zoomIn"
            }}
          >
            <div
              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <h4>24/7 Support</h4>
            <p className="mb-0">
              Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam
              dolor
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    </div>
  )
}
